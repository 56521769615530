import React, { useEffect, useContext, useState } from "react";
import { useParams } from 'react-router-dom';
import { toast } from "react-toastify";
import { makeStyles } from "@material-ui/core/styles";
import { Grid, Box } from "@material-ui/core";
import MainContainer from "../../components/MainContainer";
import MainHeader from "../../components/MainHeader";
import MainHeaderButtonsWrapper from "../../components/MainHeaderButtonsWrapper";
import Title from "../../components/Title";
import api from "../../services/api";
import { AuthContext } from "../../context/Auth/AuthContext";

const CustomFrame = () => {

    const { id } = useParams();
    const { user } = useContext(AuthContext);

    const [localMenuFrameOptionsAdmin, setLocalMenuFrameOptionsAdmin] = useState([]);
    const [localMenuFrameOptionsUser, setLocalMenuFrameOptionsUser] = useState([]);
    const [menuFrame, setMenuFrame] = useState(null);
    const [selectedOption, setSelectedOption] = useState(null);

    const fetchMenuFrames = async () => {
        try {
        const { data } = await api.get("/menuFrameOptions", {
            params: {
            MenuFrameId: id,
            },
        });

        let user_view = [];
        let admin_view = [];
        data.forEach((customMenu) => {
            if (customMenu.admin_view && customMenu.user_view) {
            admin_view.push(customMenu);
            } else if (customMenu.user_view) {
            user_view.push(customMenu);
            } else if (customMenu.admin_view) {
            admin_view.push(customMenu);
            }
        });

        setLocalMenuFrameOptionsAdmin(admin_view);
        setLocalMenuFrameOptionsUser(user_view);
        } catch (error) {
        toast.error(error);
        }
    };

    const fetchMenuFrame = async () => {
        try {
        setMenuFrame(null);
        const { data } = await api.get(`/menuFrames/${id}`);
            setMenuFrame(data);
        } catch (error) {
            toast.error(error);
        }
    };

    useEffect(() => {
        fetchMenuFrame()
        fetchMenuFrames();
    }, [id]);


    useEffect(() => {
        // Atualiza o iframe quando o selectedOption muda
        if (selectedOption) {
        const iframe = document.getElementById("customFrameIframe");
        iframe.src = selectedOption.iframeUrl;
        }
    }, [selectedOption]);

    const FrameOption = ({ menuframe }) => {
        const isSelected = selectedOption && selectedOption.id === menuframe.id;
    
        const handleClick = () => {
        setSelectedOption(null);
        setTimeout(() => {
            setSelectedOption(isSelected ? null : menuframe);
        }, 200);
        };
    
        return (
        <div
            style={{
            cursor: 'pointer',
            marginBottom: '8px', // Reduzi a margem para dar espaço à borda
            backgroundColor: isSelected ? 'lightblue' : 'transparent',
            padding: '8px',
            borderRadius: '4px',
            border: '1px solid #ccc', // Adiciona uma borda de 1px com cor cinza
            width: '100%', // Ocupa 100% da largura disponível
            }}
            onClick={handleClick}
        >
            <span style={{ fontWeight: isSelected ? 'bold' : 'normal' }}>
                {menuframe.name}
            </span>
        </div>
        );
    };
  
    return (
        <MainContainer>
        <MainHeader>
            <Title>{menuFrame && <>{menuFrame.name}</>}</Title>
        </MainHeader>
        <Grid container style={{ border: 1, borderRadius: "25px", height: '100%', width: '100%', backgroundColor: "white", padding: "20px" }}>
            <Grid xs={3} sm={3} md={3} >
                <Grid container style={{ width: '100%' }}>
                    {user.profile === 'admin' && (
                    <>
                        {localMenuFrameOptionsAdmin.map((menuFrame) => (
                        <Grid xs={12} sm={12} md={12} key={menuFrame.id}>
                            <FrameOption menuframe={menuFrame} />
                        </Grid>
                        ))}
                    </>
                    )}
                    {localMenuFrameOptionsUser.map((menuFrame) => (
                    <Grid xs={12} sm={12} md={12} key={menuFrame.id}>
                        <FrameOption menuframe={menuFrame} />
                    </Grid>
                    ))}
                </Grid>
            </Grid>
            <Grid xs={1} sm={1} md={1}></Grid>
            <Grid xs={8} sm={8} md={8} style={{
                borderRadius: '4px',
                border: '1px solid #ccc', // Adiciona uma borda de 1px com cor cinza
            }}>
                {selectedOption && (
                    <iframe
                    id="customFrameIframe"
                    key={selectedOption.id}
                    src={selectedOption.iframeUrl}
                    style={{ height: '100%', width: '100%', border: 'none' }}
                    ></iframe>
                )}
            </Grid>
        </Grid>
        </MainContainer>
    );
  
};

export default CustomFrame;
