import React, { useState, useEffect } from "react";
import {
  makeStyles,
  Paper
} from "@material-ui/core";
import { Field } from "formik";
import { InputField } from "../FormFields";
import ButtonWithSpinner from "../ButtonWithSpinner";
import { toast } from "react-toastify";
import api from "../../services/api";
import { isArray } from "lodash";
import MenuFrame from "./MenuFrame";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  mainPaper: {
    width: "100%",
    flex: 1,
    padding: theme.spacing(2),
  },
  fullWidth: {
    width: "100%",
  },
  tableContainer: {
    width: "100%",
    overflowX: "scroll",
    ...theme.scrollbarStyles,
  },
  textfield: {
    width: "100%",
  },
  textRight: {
    textAlign: "right",
  },
  row: {
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
  },
  control: {
    paddingRight: theme.spacing(1),
    paddingLeft: theme.spacing(1),
  },
  buttonContainer: {
    textAlign: "right",
    padding: theme.spacing(1),
  },
}));


const MenuFrameList = ({ companyId }) => {
  const classes = useStyles();
  const [menuFrames, setMenuFrames] = useState([])

  const fetchMenuFrames = async () => {
    try {
      setMenuFrames([])
      const { data } = await api.get("/menuFrames", {
        params: {
          companyId
        },
      });
      setMenuFrames(data)
    } catch (error) {
      toast.error(error);
    }
  }

  useEffect(() => {
    fetchMenuFrames();
  }, [companyId]);

  const AddMenuFrame = async () => {
    try {
      const formData = {
        name: "",
        description: "",
        user_view: false,
        admin_view: false
      };
      await api.post(
        `/menuFrames?companyId=${companyId}`,
        formData
      );
      fetchMenuFrames()
      toast.success("sucess");
    } catch (error) {
      toast.error(error);
    }
  }

  return (
    <Paper className={classes.mainPaper} elevation={0}>
      {menuFrames.map((menuFrame) => <div style={{marginBottom: "30px"}}><MenuFrame menuframe={menuFrame} updatedCallback={fetchMenuFrames} /></div>)}
      <ButtonWithSpinner
        style={{ marginTop: 7 }}
        className={classes.fullWidth}
        loading={false}
        onClick={() => AddMenuFrame()}
        variant="contained"
        color="primary"
      >
        + MENU
      </ButtonWithSpinner>
    </Paper>
  );
};

export default MenuFrameList;