import React, { Fragment, useState, useCallback, useMemo, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Calendar, Views, momentLocalizer } from 'react-big-calendar';
import moment from 'moment';
import { makeStyles, Paper, Tabs, Tab, TextField, Button, Dialog, DialogTitle, DialogContent, DialogActions } from '@material-ui/core';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { DateTimePicker } from '@mui/x-date-pickers';
import api from "../../services/api";
import toastError from "../../errors/toastError";

const useStyles = makeStyles({
    paper: {
        flexGrow: 1,
    },
    container: {
        height: 600,
    },
    modalInput: {
        marginBottom: 20,
    },
});

export default function CustomCalendarView() {
    const localizer = momentLocalizer(moment);

    const classes = useStyles();
    const [eventData, setEventData] = useState({
        title: '',
        start: '',
        end: ''
    });
    const [modalIsOpen, setModalIsOpen] = useState(false);
    const [myEvents, setMyEvents] = useState([]);
    const [responseInfo, setResponseInfo] = useState([]);
    const [tabIndex, setTabIndex] = useState(0);
    const [selectedEvent, setSelectedEvent] = useState(null);
    const [editMode, setEditMode] = useState(false);

    useEffect(() => {
        loadEvents();
    }, []);

    const loadEvents = async () => {
        try {
            const response = await api.get('/company-calendar');
            const formattedEvents = response.data.events.map(event => ({
                ...event,
                start: new Date(event.start),
                end: new Date(event.end)
            }));
            setMyEvents(formattedEvents);
            setResponseInfo(response.data);
        } catch (error) {
            console.error('Error loading events:', error);
            toastError(error);
        }
    };

    const openModal = () => {
        setModalIsOpen(true);
    };

    const closeModal = () => {
        setModalIsOpen(false);
        setEventData({
            title: '',
            start: '',
            end: ''
        });
        setEditMode(false);
    };

    const handleSelectSlot = useCallback(({ start, end }) => {
        setEventData({
            title: '',
            start,
            end
        });
        setModalIsOpen(true);
        setEditMode(false);
    }, [setEventData]);

    const updateEvents = async (Events) => {
        try {
            const { data } = await api.put(`/company-calendar/${responseInfo.companyId}`, {
                ...responseInfo,
                events: Events
            });
    
            const formattedEvents = data.events.map(event => ({
                ...event,
                start: new Date(event.start),
                end: new Date(event.end)
            }));
    
            setMyEvents(formattedEvents);
            setResponseInfo(data);
        } catch (error) {
            console.error(error);
            toastError(error);
        }
    };

    const handleAddEvent = async () => {
        
        if (editMode && selectedEvent) {
            // Edit mode
            const updatedEvent = { ...selectedEvent, title: eventData.title, start: eventData.start, end: eventData.end };
            const updatedEvents = myEvents.map(event => (event === selectedEvent ? updatedEvent : event));
            console.log(updatedEvents)
            setMyEvents(updatedEvents);
            updateEvents(updatedEvents)
        } else {
            // Add mode
            const start = new Date(eventData.start);
            const end = new Date(eventData.end);
            let events = [...myEvents,{ start, end, title: eventData.title }]
            setMyEvents(events);
            updateEvents(events)
        }
        closeModal();
    };

    const handleRemoveEvent = async () => {
        if (selectedEvent) {
            const updatedEvents = myEvents.filter(event => event !== selectedEvent);
            setMyEvents(updatedEvents);
            updateEvents(updatedEvents)
            closeModal();
        }
    };

    const handleInputChange = (event) => {
        const { name, value } = event.target;
        setEventData(prev => ({
            ...prev,
            [name]: value
        }));
    };

    const handleInputDateChange = (field, data) => {
        setEventData(prev => ({
            ...prev,
            [field]: data
        }));
    };

    const handleTabChange = (event, newValue) => {
        setTabIndex(newValue);
    };


    const lastYear = new Date().getFullYear() - 1;
    const lastYearStart = new Date(lastYear, 0, 1);
    const lastYearEnd = new Date(lastYear, 11, 31);

    const { scrollToTime } = useMemo(
        () => ({
            scrollToTime: new Date(),
        }),
        []
    );

    return (
        <Fragment>
            <div className={classes.container}>
                <Calendar
                    defaultDate={new Date()}
                    defaultView={Views.MONTH}
                    events={myEvents}
                    localizer={localizer}
                    onSelectSlot={handleSelectSlot}
                    onDoubleClickEvent={(event) => {
                        setSelectedEvent(event);
                        console.log(event.start)
                        setEventData({
                            title: event.title,
                            start: event.start,
                            end: event.end,
                        });
                        setModalIsOpen(true);
                        setEditMode(true);
                    }}
                    selectable
                    scrollToTime={scrollToTime}
                    min={lastYearStart}
                    max={lastYearEnd}
                />
                <Dialog open={modalIsOpen} onClose={closeModal}>
                    <DialogTitle>{editMode ? 'Edit Event' : 'Add Event'}</DialogTitle>
                    <DialogContent>
                        <TextField
                            className={classes.modalInput}
                            label="Event Name"
                            variant="outlined"
                            name="title"
                            value={eventData.title}
                            onChange={handleInputChange}
                            fullWidth
                        />
                        <DateTimePicker
                            className={classes.modalInput}
                            label="Start Date"
                            type="date"
                            name="start"
                            value={eventData.start}
                            onChange={(d) => handleInputDateChange("start", d)}
                            fullWidth
                        />
                        <DateTimePicker
                            className={classes.modalInput}
                            label="End Date"
                            type="date"
                            name="end"
                            value={eventData.end}
                            onChange={(d) => handleInputDateChange("end", d)}
                            fullWidth
                        />
                    </DialogContent>
                    <DialogActions>
                        {editMode && (
                            <Button onClick={handleRemoveEvent} color="secondary">
                                Remove
                            </Button>
                        )}
                        <Button onClick={handleAddEvent} color="primary">
                            {editMode ? 'Save' : 'Add'}
                        </Button>
                        <Button onClick={closeModal} color="secondary">
                            Cancel
                        </Button>
                    </DialogActions>
                </Dialog>
            </div>
        </Fragment>
    );
}
