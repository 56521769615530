import React, { useState, useEffect } from "react";
import { makeStyles, TextField, Grid, Button } from "@material-ui/core";
import { Formik, Form, FastField, FieldArray } from "formik";
import { isArray } from "lodash";
import NumberFormat from "react-number-format";
import ButtonWithSpinner from "../ButtonWithSpinner";
import { Save, Trash } from "lucide-react";
import { Add } from "@material-ui/icons";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  fullWidth: {
    width: "100%",
  },
  textfield: {
    width: "100%",
  },
  row: {
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
  },
  control: {
    paddingRight: theme.spacing(1),
    paddingLeft: theme.spacing(1),
  },
  buttonContainer: {
    textAlign: "right",
    padding: theme.spacing(1),
  },
}));

function SchedulesForm(props) {
  const { initialValues, onSubmit, loading, labelSaveButton } = props;
  const classes = useStyles();

  const [schedules, setSchedules] = useState([
    { weekday: "Segunda-feira", weekdayEn: "monday", times: [{ startTime: "", endTime: "" }] },
    { weekday: "Terça-feira", weekdayEn: "tuesday", times: [{ startTime: "", endTime: "" }] },
    { weekday: "Quarta-feira", weekdayEn: "wednesday", times: [{ startTime: "", endTime: "" }] },
    { weekday: "Quinta-feira", weekdayEn: "thursday", times: [{ startTime: "", endTime: "" }] },
    { weekday: "Sexta-feira", weekdayEn: "friday", times: [{ startTime: "", endTime: "" }] },
    { weekday: "Sábado", weekdayEn: "saturday", times: [{ startTime: "", endTime: "" }] },
    { weekday: "Domingo", weekdayEn: "sunday", times: [{ startTime: "", endTime: "" }] },
  ]);

  useEffect(() => {
    if (isArray(initialValues) && initialValues.length > 0) {
      for (let item of initialValues) {
        if (item['times'] == undefined) {
          item['times'] = [{ startTime: item.startTime, endTime: item.endTime }]
          delete item.startTime;
          delete item.endTime
        }
      }
      setSchedules(initialValues);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initialValues]);


  const handleChangeTime = (event, scheduleIndex, timeIndex, field) => {
    console.log(schedules)
    const updatedSchedules = [...schedules];
    updatedSchedules[scheduleIndex].times[timeIndex][field] = event.target.value;
    setSchedules(updatedSchedules);
  };

  const handleSubmit = (data) => {
    onSubmit(data);
  };

  const addTime = (index) => {
    const updatedSchedules = [...schedules];
    updatedSchedules[index].times.push({ startTime: "", endTime: "" });
    setSchedules(updatedSchedules);
  };

  const delTime = (index, timeIndex) => {
    const updatedSchedules = [...schedules];
    updatedSchedules[index].times.splice(timeIndex, 1);
    setSchedules(updatedSchedules);
  };

  return (
    <Formik
      enableReinitialize
      className={classes.fullWidth}
      initialValues={{ schedules }}
      onSubmit={({ schedules }) =>
        setTimeout(() => {
          handleSubmit(schedules);
        }, 500)
      }
    >
      {({ values }) => (
        <Form className={classes.fullWidth}>
          <FieldArray
            name="schedules"
            render={(arrayHelpers) => (
              <Grid spacing={4} container>
                {values.schedules.map((item, index) => (
                  <Grid key={index} xs={12} md={4} item>
                    <Grid container>
                      <Grid className={classes.control} xs={12} item>
                        <FastField
                          as={TextField}
                          label="Dia da Semana"
                          value={item.weekday}
                          disabled
                          variant="outlined"
                          className={classes.fullWidth}
                          margin="dense"
                        />
                      </Grid>
                      {item.times.map((time, timeIndex) => (
                        <React.Fragment key={timeIndex}>
                          <Grid className={classes.control} xs={12} md={5} item>
                            <FastField
                              label="Hora de Inicial"
                              component={NumberFormat}
                              variant="outlined"
                              margin="dense"
                              customInput={TextField}
                              format="##:##"
                              className={classes.fullWidth}
                              value={time.startTime}
                              onChange={(e)=>{
                                handleChangeTime(e, index, timeIndex, "startTime")
                              }}
                            />
                          </Grid>
                          <Grid className={classes.control} xs={12} md={5} item>
                            <FastField
                              label="Hora de Final"
                              component={NumberFormat}
                              variant="outlined"
                              margin="dense"
                              customInput={TextField}
                              format="##:##"
                              className={classes.fullWidth}
                              value={time.endTime}
                              onChange={(e)=>{
                                handleChangeTime(e, index, timeIndex, "endTime")
                              }}
                            />
                          </Grid>
                          <Grid item xs={2} className={classes.control}>
                            <Button
                              color="primary"
                              variant="contained"
                              onClick={() => delTime(index, timeIndex)}
                              style={{marginTop:"10px"}}
                            >
                              <Trash></Trash>
                            </Button>
                          </Grid>
                        </React.Fragment>
                      ))}
                      <Grid item xs={12} className={classes.control}>
                        <Button
                          color="primary"
                          variant="contained"
                          onClick={() => addTime(index)}
                        >
                          <Add></Add>
                        </Button>
                      </Grid>
                    </Grid>
                  </Grid>
                ))}
              </Grid>
            )}
          ></FieldArray>
          <div className={classes.buttonContainer}>
            <ButtonWithSpinner
              loading={loading}
              type="submit"
              color="primary"
              variant="contained"
            >
              {labelSaveButton ?? "Salvar"}
            </ButtonWithSpinner>
          </div>
        </Form>
      )}
    </Formik>
  );
}

export default SchedulesForm;
