import React, { useState } from "react";
import { toast } from "react-toastify";
import { Grid, TextField, Checkbox, FormControlLabel, Button, Box } from "@material-ui/core";
import ButtonWithSpinner from "../ButtonWithSpinner";
import api from "../../services/api";

const FrameOption = ({ FrameOption, updatedCallback = () => { } }) => {
    const [localFrameOption, setLocalFrameOption] = useState(FrameOption);

    const MenuFrameHandleChange = (event) => {
        const { name, value, checked } = event.target;
        setLocalFrameOption((prevMenuFrame) => ({
            ...prevMenuFrame,
            [name]: name === "user_view" || name === "admin_view" ? checked : value,
        }));
    };

    const updateMenuFrame = async () => {
        try {
            await api.put(`/menuFrameOptions/${localFrameOption.id}?companyId=${localFrameOption.companyId}`, localFrameOption);
            updatedCallback();
            toast.success("sucess");
        } catch (error) {
            toast.error(error.message);
        }
    };

    const deleteMenuFrame = async () => {
        try {
            await api.delete(`/menuFrameOptions/${localFrameOption.id}?companyId=${localFrameOption.companyId}`);
            updatedCallback();
            toast.success("sucess");
        } catch (error) {
            toast.error(error.message);
        }
    };

    return (
        <Grid container>
            <Grid item xs={12} sm={6} md={2}>
                <TextField
                    label="Nome do Menu"
                    variant="outlined"
                    name="name"
                    value={localFrameOption.name}
                    onChange={MenuFrameHandleChange}
                />
            </Grid>
            <Grid item xs={12} sm={6} md={2}>
                <TextField
                    label="iframeUrl"
                    variant="outlined"
                    name="iframeUrl"
                    value={localFrameOption.iframeUrl}
                    onChange={MenuFrameHandleChange}
                />
            </Grid>
            <Grid item xs={12} sm={6} md={2}>
                <FormControlLabel
                    control={
                        <Checkbox
                            color="primary"
                            checked={localFrameOption.user_view}
                            onChange={MenuFrameHandleChange}
                            name="user_view"
                        />
                    }
                    label="UView"
                    labelPlacement="start"
                />
                <FormControlLabel
                    control={
                        <Checkbox
                            color="primary"
                            checked={localFrameOption.admin_view}
                            onChange={MenuFrameHandleChange}
                            name="admin_view"
                        />
                    }
                    label="AView"
                    labelPlacement="start"
                />
            </Grid>
            <Grid item xs={12} sm={6} md={2}>
                <Button variant="contained" color="primary" onClick={updateMenuFrame}>
                    Salvar
                </Button>
                <Button variant="contained" color="secondary" onClick={deleteMenuFrame}>
                    Deletar
                </Button>
            </Grid>
        </Grid>
    );
};

export default FrameOption;
