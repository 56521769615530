import React, { useState, useEffect } from "react";
import { toast } from "react-toastify";
import { Grid, TextField, Checkbox, FormControlLabel, Button, Box } from "@material-ui/core";
import ButtonWithSpinner from "../ButtonWithSpinner";
import FrameOption from "./FrameOption";
import api from "../../services/api";

const MenuFrame = ({ menuframe, updatedCallback = () => { } }) => {
    const [localMenuFrame, setLocalMenuFrame] = useState(menuframe);
    const [localMenuFrameOptions, setLocalMenuFrameOptions] = useState([]);
    
    const fetchMenuFrames = async () => {
        try {
            setLocalMenuFrameOptions([])
            const { data } = await api.get("/menuFrameOptions", {
                params: {
                    MenuFrameId: menuframe.id
                },
            });
            setLocalMenuFrameOptions(data)
        } catch (error) {
          toast.error(error);
        }
    }

    const AddMenuFrameOption = async () => {
        try {
          const formData = {
            name: "",
            description: "",
            user_view: false,
            admin_view: false,
            iframeUrl: "",
            MenuFrameId: menuframe.id,
          };
          await api.post(
            `/menuFrameOptions?companyId=${localMenuFrame.companyId}`,
            formData
          );
          
          toast.success("sucess");
          fetchMenuFrames()
        } catch (error) {
          toast.error(error);
        }
    }

    useEffect(() => {
        // AddMenuFrameOption();
        fetchMenuFrames()
    }, []);

    const MenuFrameHandleChange = (event) => {
        const { name, value, checked } = event.target;
        setLocalMenuFrame((prevMenuFrame) => ({
            ...prevMenuFrame,
            [name]: name === "user_view" || name === "admin_view" ? checked : value,
        }));
    };

    const updateMenuFrame = async () => {
        try {
            await api.put(`/menuFrames/${localMenuFrame.id}?companyId=${localMenuFrame.companyId}`, localMenuFrame);
            updatedCallback();
            toast.success("sucess");
        } catch (error) {
            toast.error(error.message);
        }
    };

    // Função para deletar um MenuFrame
    const deleteMenuFrame = async () => {
        try {
            await api.delete(`/menuFrames/${localMenuFrame.id}?companyId=${localMenuFrame.companyId}`);
            updatedCallback();
            toast.success("sucess");
        } catch (error) {
            toast.error(error.message);
        }
    };

    return (
        <Box sx={{ border: 1 }}>
            <Grid container>
                <Grid container xs={12} sm={12} md={12}>
                    <Grid item xs={12} sm={6} md={3}>
                        <TextField
                            label="Nome do Menu"
                            variant="outlined"
                            name="name"
                            value={localMenuFrame.name}
                            onChange={MenuFrameHandleChange}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6} md={3}>
                        <TextField
                            label="Descrição do Menu"
                            variant="outlined"
                            name="description"
                            value={localMenuFrame.description}
                            onChange={MenuFrameHandleChange}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6} md={1}>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    color="primary"
                                    checked={localMenuFrame.user_view}
                                    onChange={MenuFrameHandleChange}
                                    name="user_view"
                                />
                            }
                            label="Usuário"
                            labelPlacement="start"
                        />
                    </Grid>
                    <Grid item xs={12} sm={6} md={1}>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    color="primary"
                                    checked={localMenuFrame.admin_view}
                                    onChange={MenuFrameHandleChange}
                                    name="admin_view"
                                />
                            }
                            label="Admin"
                            labelPlacement="start"
                        />
                    </Grid>
                    <Grid item xs={12} sm={6} md={2}>
                        <Button variant="contained" color="primary" onClick={updateMenuFrame}>
                            Salvar
                        </Button>
                        <Button variant="contained" color="secondary" onClick={deleteMenuFrame}>
                            Deletar
                        </Button>
                    </Grid>
                </Grid>
                <Grid container xs={12} sm={12} md={12} style={{marginLeft:"30px"}}>
                    <Grid item xs={10} sm={10} md={10}>
                        {localMenuFrameOptions.map((frameOption) => 
                        <div style={{margin: "15px"}}>
                            <FrameOption FrameOption={frameOption} updatedCallback={fetchMenuFrames}/>
                        </div>
                        )}
                    </Grid>
                    <Grid item xs={12} sm={12} md={12}>
                        <ButtonWithSpinner
                            style={{ marginTop: 2 }}
                            loading={false}
                            onClick={() => AddMenuFrameOption()}
                            variant="contained"
                            color="primary"
                        >
                            + Frame
                        </ButtonWithSpinner>
                    </Grid>
                    
                </Grid>
            </Grid>
        </Box>
        
    );
};

export default MenuFrame;
