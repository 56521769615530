import React, { useState, useEffect, useContext } from "react";
import { makeStyles } from "@material-ui/core/styles";
import api from "../../services/api";
import { AuthContext } from "../../context/Auth/AuthContext";
import Board from "react-trello";
import { toast } from "react-toastify";
import { i18n } from "../../translate/i18n";
import { useHistory } from "react-router-dom";
import SearchIcon from "@material-ui/icons/Search";
import InputBase from "@material-ui/core/InputBase";
import Paper from "@material-ui/core/Paper";
import Button from "@material-ui/core/Button"; // Importe o botão do Material-UI
import BoardSettingsModal from '../../components/kanbanModal';
import IconButton from "@material-ui/core/IconButton";
import SettingsIcon from "@material-ui/icons/Settings";


const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: theme.spacing(1),
  },
  header: {
    display: "flex",
    alignItems: "center",
    marginBottom: theme.spacing(2),
  },
  search: {
    position: "relative",
    marginLeft: 0,
    width: "100%",
    backgroundColor: "rgba(252, 252, 252, 0.03)",
  },
  searchIcon: {
    padding: theme.spacing(0, 2),
    height: "100%",
    position: "absolute",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    color: theme.palette.primary.main,
  },
  inputRoot: {
    color: "inherit",
  },
  inputInput: {
    padding: theme.spacing(1, 1, 1, 0),
    paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
    width: "100%",
  },
  button: {
    margin: theme.spacing(2),
  },
}));

const ContactKanban = () => {
  const classes = useStyles();
  const history = useHistory();

  const [settingsModalOpen, setSettingsModalOpen] = useState(false);

  const [tags, setTags] = useState([]);
  const [reloadData, setReloadData] = useState(false);
  const [isInitialLoadComplete, setIsInitialLoadComplete] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");


  const handleOpenBoardSettings = () => {
    setSettingsModalOpen(true);
  };

  const handleSendDataToWebhook = async (tagId, contacts) => {
    try {
      // Coletar apenas os dados do quadro selecionado
      const selectedData = {
        tagId,
        contacts,
        // Adicione outros dados conforme necessário
      };
  
      // Enviar dados para a nova rota do webhook
      await api.get('/kanbanWebhook', selectedData);
  
      // Exibir uma notificação ou mensagem de sucesso
      toast.success('Dados do quadro enviados com sucesso para a chamada webhook!');
    } catch (error) {
      console.error('Erro ao enviar dados para a chamada webhook:', error.message);
      // Exibir uma notificação ou mensagem de erro, se necessário
      toast.error('Erro ao enviar dados para a chamada webhook.');
    }
  };
  

  const fetchTags = async () => {
    try {
      const response = await api.get("/tags/kanban");
      const fetchedTags = response.data.lista || [];

      setTags(fetchedTags);

      // Fetch contacts after fetching tags
      await fetchContacts(jsonString);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    fetchTags();
  }, []);

  const [file, setFile] = useState({
    lanes: [],
  });
  
  const [contacts, setContacts] = useState([]);
  const { user } = useContext(AuthContext);
  const { profile, queues } = user;
  const jsonString = user.queues.map((queue) => queue.UserQueue.queueId);

  const fetchContacts = async (jsonString) => {
    try {
      const { data } = await api.get("/contacts/kanban", {
        params: {
          queueIds: JSON.stringify(jsonString),
          teste: true,
        },
      });
      setContacts(data.contacts);
    } catch (err) {
      console.log(err);
      setContacts([]);
    }
  };
  

  const popularCards = (jsonString) => {
    const filteredContacts = contacts.filter(
      (contact) => contact.tags.length === 0
    );

    const lanes = [
      {
        id: "lane0",
        title: i18n.t("Em aberto"),
        label: "0",
        cards: filteredContacts
          .filter((contact) => contactMatchesSearchQuery(contact))
          .map((contact) => ({
            id: contact.id.toString(),
            label: "Contact nº " + contact.id.toString(),
            description: (
              
              <div>
                <p>
                  {contact.number}
                  <br />
                  {contact.lastMessage}
                </p>
                {/* <Button
                  variant="contained"
                  color="primary"
                  className={classes.button} // Aplica o estilo do botão
                  onClick={() => {
                    handleCardClick(contact.uuid);
                  }}
                >
                  Ver Contact
                </Button> */}
                
              </div>
              
            ),
            title: contact.name,
            draggable: true,
            href: "/contacts/" + contact.uuid,
          })),
      },
      ...tags.map((tag) => {
        const filteredContacts = contacts.filter((contact) => {
          const tagIds = contact.tags.map((tag) => tag.id);
          return tagIds.includes(tag.id);
        });

        return {
          id: tag.id.toString(),
          title: tag.name,
          label: tag.id.toString(),
          cards: filteredContacts
            .filter((contact) => contactMatchesSearchQuery(contact))
            .map((contact) => ({
              id: contact.id.toString(),
              label: "Contact nº " + contact.id.toString(),
              description: (
                <div>
                  <p>
                    {contact.number}
                    <br />
                    {contact.lastMessage}
                  </p>
                  {/* <Button
                    variant="contained"
                    color="primary"
                    className={classes.button} // Aplica o estilo do botão
                    onClick={() => {
                      console.log(contact.uuid);
                      handleCardClick(contact.uuid);
                    }}
                  >
                    Ver Contact
                  </Button> */}
                  
                
                </div>
              ),
              title: contact.name,
              draggable: true,
              href: "/contacts/" + contact.uuid,
            })),
          style: { backgroundColor: tag.color, color: "white" },
        };
      }),
    ];

    setFile({ lanes });
  };

  const handleCardClick = (uuid) => {
    history.push("/contacts/" + uuid);
  };

  useEffect(() => {
    popularCards(jsonString);
  }, [tags, contacts, reloadData, searchQuery]);

  const handleCardMove = async (cardId, sourceLaneId, targetLaneId) => {
    try {
      // Remova a tag da lane de destino
      await api.delete(`/contact-tags/${targetLaneId}`);
   
      await fetchContacts(jsonString);

      // Adicione a tag à lane de origem
      await api.put(`/contact-tags/${targetLaneId}/${sourceLaneId}`);
      

      // Busque os contacts atualizados
      await fetchContacts(jsonString);

      // Atualize o estado local dos contacts
      popularCards(jsonString);
    } catch (err) {
      console.log(err);
    }
  };

  const contactMatchesSearchQuery = (contact) => {
    if (searchQuery.trim() === "") {
      return true;
    }

    const query = searchQuery.toLowerCase();
    return (
      contact.number.toLowerCase().includes(query) ||
      contact.lastMessage.toLowerCase().includes(query) ||
      contact.name.toLowerCase().includes(query)
    );
  };

  const handleSearchQueryChange = (e) => {
    setSearchQuery(e.target.value);
  };
  

  return (
    <div className={classes.root}>
      <div className={classes.header}>
        <Paper className={classes.search}>
          <div className={classes.searchIcon}>
            <SearchIcon />
          </div>
          <InputBase
            placeholder="Pesquisar..."
            classes={{
              root: classes.inputRoot,
              input: classes.inputInput,
            }}
            inputProps={{ "aria-label": "search" }}
            value={searchQuery}
            onChange={handleSearchQueryChange}
          />
        </Paper>
        {/* IconButton com o ícone de engrenagem 
        <IconButton
          color="primary"
          className={classes.button}
          onClick={handleOpenBoardSettings}
        >
          <SettingsIcon />
        </IconButton>

        { Modal de configurações do quadro *}
        {settingsModalOpen && (
          <BoardSettingsModal
            open={settingsModalOpen}
            onClose={() => setSettingsModalOpen(false)}
          />
        )*/}
      </div>
      
      <Board
        data={file}
        onCardMoveAcrossLanes={handleCardMove}
        style={{ backgroundColor: "rgba(252, 252, 252, 0.03)" , width: "100%"}}
        scrollbarStyles={{
          "&::-webkit-scrollbar": {
            width: "8px",
            height: "8px",
          },
          "&::-webkit-scrollbar-thumb": {
            boxShadow: "inset 0 0 6px rgba(0, 0, 0, 0.3)",
            backgroundColor: "#000",
          },
        }}
        
      />
      
    </div>
  );
};

export default ContactKanban;