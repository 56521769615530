import React from "react";
import ReactDOM from "react-dom";
import CssBaseline from "@material-ui/core/CssBaseline";
import * as serviceworker from './serviceWorker';
import App from "./App";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { ptBR } from "date-fns/locale";

 //Sobrescrevendo as funções do console em ambiente de produção
if (process.env.NODE_ENV === 'production') {
  console.log = console.error = console.warn = () => {};
}

ReactDOM.render(
	<LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={ptBR}>
		<CssBaseline>
			<App />
		</CssBaseline>
	</LocalizationProvider>,
	document.getElementById("root")
);

serviceworker.register()
